import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/Index",
    component: () => import("../views/m/Index.vue"),
  },
  {
    path: "/m/index",
    name: "MIndex",
    component: () => import("../views/m/Index.vue"),
  },
  {
    path: "/m/commanddemo",
    name: "MCommandDemo",
    component: () => import("../views/m/CommandDemo.vue"),
  },
  {
    path: "/m/commanddemo1",
    name: "MCommandDemo1",
    component: () => import("../views/m/CommandDemo1.vue"),
  },
  {
    path: "/m/tongchengtravel",
    name: "MTongChengtravel",
    component: () => import("../views/m/tongcheng-travel.vue"),
  },
  {
    path: "/m/tongcheng_welfare",
    name: "MTongChengWelfare",
    component: () => import("../views/m/tongcheng_welfare.vue"),
  },
  {
    path: "/m/tongcheng_jinka",
    name: "MTongChengJinka",
    component: () => import("../views/m/tongcheng_jinka.vue"),
  },
  {
    path: "/tc6601",
    name: "Tc6601",
    component: () => import("../views/m/tongcheng_jinka_6601.vue"),
  },
  {
    path: "/m/tongcheng_jinka_foshan",
    name: "MTongChengJinkaFoShan",
    component: () => import("../views/m/tongcheng_jinka_foshan.vue"),
  },
  {
    path: "/tc6602",
    name: "Tc6602",
    component: () => import("../views/m/tongcheng_jinka_foshan_6602.vue"),
  },
  {
    path: "/m/yuanchu",
    name: "MYuanchu",
    component: () => import("../views/m/yuanchu.vue"),
  },
  {
    path: "/pc/index",
    name: "PcIndex",
    component: () => import("../views/pc/Index.vue"),
  },
  {
    path: "/cron1800",
    name: "Cron1800",
    component: () => import("../views/Cron1800.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/frame",
    name: "Frame",
    component: () => import("../components/Frame.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/notice",
        name: "Notice",
        component: () => import("../views/notice/Notice.vue"),
        redirect: "/notice/UnRead",
        children: [
          {
            path: "/notice/unRead",
            name: "UnRead",
            component: () => import("../views/notice/UnRead.vue"),
          },
          {
            path: "/notice/read",
            name: "Read",
            component: () => import("../views/notice/Read.vue"),
          },
        ],
      },
      {
        path: "/manage/admin",
        name: "Admin",
        component: () => import("../views/manage/Admin.vue"),
      },
      {
        path: "/manage/employees",
        name: "Employees",
        component: () => import("../views/manage/Employees.vue"),
      },
      {
        path: "/personal",
        name: "Personal",
        component: () => import("../views/Personal.vue"),
      },
      {
        path: "/news",
        name: "News",
        component: () => import("../views/news/index.vue"),
      },
      {
        path: "/news/add",
        name: "NewsAdd",
        component: () => import("../views/news/add.vue"),
      },
      {
        path: "/admin/table/list",
        name: "AdminTableList",
        component: () => import("../views/admin/table/List.vue"),
      },
      {
        path: "/admin/table/add",
        name: "AdminTableAdd",
        component: () => import("../views/admin/table/Add.vue"),
      },
      {
        path: "/admin/table/update",
        name: "AdminTableUpdate",
        component: () => import("../views/admin/table/Update.vue"),
      },
      {
        path: "/admin/product/list",
        name: "AdminProductList",
        component: () => import("../views/admin/product/List.vue"),
      },
      {
        path: "/admin/product/add",
        name: "AdminProductAdd",
        component: () => import("../views/admin/product/Add.vue"),
      },
      {
        path: "/admin/product/update",
        name: "AdminProductUpdate",
        component: () => import("../views/admin/product/Update.vue"),
      },
      {
        path: "/admin/admin/list",
        name: "AdminAdminList",
        component: () => import("../views/admin/admin/List.vue"),
      },
      {
        path: "/admin/admin/add",
        name: "AdminAdminAdd",
        component: () => import("../views/admin/admin/Add.vue"),
      },
      {
        path: "/admin/admin/update",
        name: "AdminAdminUpdate",
        component: () => import("../views/admin/admin/Update.vue"),
      },
      {
        path: "/admin/article/list",
        name: "AdminArticleList",
        component: () => import("../views/admin/article/List.vue"),
      },
      {
        path: "/admin/article/add",
        name: "AdminArticleAdd",
        component: () => import("../views/admin/article/Add.vue"),
      },
      {
        path: "/admin/article/update",
        name: "AdminArticleUpdate",
        component: () => import("../views/admin/article/Update.vue"),
      },
      {
        path: "/admin/eleme/list",
        name: "AdminElemeList",
        component: () => import("../views/admin/eleme/List.vue"),
      },
      {
        path: "/admin/eleme/add",
        name: "AdminElemeAdd",
        component: () => import("../views/admin/eleme/Add.vue"),
      },
      {
        path: "/admin/eleme/update",
        name: "AdminElemeUpdate",
        component: () => import("../views/admin/eleme/Update.vue"),
      },
      {
        path: "/permission/index.vue",
        name: "permission",
        component: () => import("../views/permission/Index.vue"),
      },
      {
        path: "/admin/company/list",
        name: "AdminCompanyList",
        component: () => import("../views/admin/company/List.vue"),
      },
      {
        path: "/admin/company/add",
        name: "AdminCompanyAdd",
        component: () => import("../views/admin/company/Add.vue"),
      },
      {
        path: "/admin/company/update",
        name: "AdminCompanyUpdate",
        component: () => import("../views/admin/company/Update.vue"),
      },
      {
        path: "/admin/company/product/list",
        name: "AdminCompanyProductList",
        component: () => import("../views/admin/company/product/List.vue"),
      },
      {
        path: "/admin/company/product/add",
        name: "AdminCompanyProductAdd",
        component: () => import("../views/admin/company/product/Add.vue"),
      },
      {
        path: "/admin/company/product/update",
        name: "AdminCompanyProductUpdate",
        component: () => import("../views/admin/company/product/Update.vue"),
      },
      {
        path: "/admin/command/list",
        name: "AdminCommandList",
        component: () => import("../views/admin/command/List.vue"),
      },
      {
        path: "/admin/command/update",
        name: "AdminCommandUpdate",
        component: () => import("../views/admin/command/Update.vue"),
      },
      {
        path: "/admin/alipay/list",
        name: "AdminAlipayList",
        component: () => import("../views/admin/alipay/List.vue"),
      },
      {
        path: "/admin/alipay/update",
        name: "AdminAlipayUpdate",
        component: () => import("../views/admin/alipay/Update.vue"),
      },
      {
        path: "/admin/halfscreen/list",
        name: "AdminHalfscreenList",
        component: () => import("../views/admin/halfscreen/List.vue"),
      },
      {
        path: "/admin/halfscreen/update",
        name: "AdminHalfscreenUpdate",
        component: () => import("../views/admin/halfscreen/Update.vue"),
      },
    ],
  },
  {
    path: "/read",
    name: "Read",
    component: () => import("../views/Read.vue"),
  },
  {
    path: "/commandstat",
    name: "CommandStat",
    component: () => import("../views/CommandStat.vue"),
  },
  {
    path: "/alipay3301",
    name: "Alipay3301",
    component: () => import("../views/Alipay3301.vue"),
  },
  {
    path: "/cpa5501",
    name: "Cpa5501",
    component: () => import("../views/Cpa5501.vue"),
  },
  {
    path: "/ks",
    name: "Ks",
    component: () => import("../views/ks.vue"),
  },
  {
    path: "/kuaishou",
    name: "Kuaishou",
    component: () => import("../views/kuaishou.vue"),
  },
  {
    path: "/ka",
    name: "Ka",
    component: () => import("../views/ka.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

//避免重复点击导航爆错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
